import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format, parseISO } from "date-fns";

import { formatCurrency } from "../../../../common/utils/helper";
import Ptable from "../../../../common/components/base/table/Table";
import CardStatementGenerateForm from "./CardStatementGenerateForm";
import TableNavigator from "../../../../common/components/base/tableNavigator/tableNavigator";

import { CARD_ID_FIELD, CARD_STATEMENT_HOME_URL } from "../../common/utils";
import { fetchCardStatement, fetchCardStatementReports } from "../../thunk";

const cardDetailsHeaders = [
    "CARD NUMBER",
    "NAME",
    "BALANCE",
    "STATUS"
]

const cardDetailsFields = [
    "ACCOUNTNUMBER",
    "NAME",
    "BALANCE",
    "STATUS"
]

const cardStatementHeaders = [
    "START DATE",
    "END DATE",
    "TYPE",
    "STATUS",
    "ACTIONS"
]

const cardStatementFields = [
    "startDate",
    "endDate",
    "type",
    "status"
]

const reportTableActions = [
    {
        type: "download"
    }
]

export default function CardStatementReportList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const {statement, statementReport} = useSelector(state => state.cardStatement);
    const data = statementReport.data || [];
    const {OFFSET, LIMIT, TOTAL} = statementReport.metadata || {};
    const currentOffset = useRef(OFFSET);

    const dataWithDates = data.map(d => {
        try {
            const paramJson = JSON.parse(d.REQUESTEDREPORTPARAMETERS);
            return {
                ...d,
                id: d.REQUESTEDREPORTID,
                downloadLink: d.REQUESTEDREPORTRESULTURL,
                status: d.REQUESTEDREPORTSTATUS,
                type: paramJson.type,
                startDate: format(parseISO(paramJson.startDate), 'yyyy-MM-dd'),
                endDate: format(parseISO(paramJson.endDate), 'yyyy-MM-dd'),
            };
        } catch (error) {
            return d;
        }
    });

    const getCardData = () => {
        return {
            ...statement.data,
            id: 'profile-history-profile',
            BALANCE: formatCurrency(statement.data.VOUCHER.BALANCEAMOUNT),
            STATUS: statement.data.VOUCHER.STATUS,
            NAME: `${statement.data.ACCOUNTCARDFIRSTNAME} ${statement.data.ACCOUNTCARDSURNAME}`
        }
    }

    const refreshReportStatus = () => {
        dispatch(fetchCardStatementReports(
            {
                [CARD_ID_FIELD]: params.get(CARD_ID_FIELD),
                limit: LIMIT,
                offset: currentOffset.current
            }
        ))
    }
    const handleNext = () => {
        currentOffset.current = OFFSET + LIMIT;
        refreshReportStatus();
    }

    const handlePrevious = () => {
        currentOffset.current = OFFSET - LIMIT;
        refreshReportStatus();
    }

    useEffect(() => {
        if (params.get(CARD_ID_FIELD)) {
            dispatch(fetchCardStatement({[CARD_ID_FIELD]: params.get(CARD_ID_FIELD)}));
            refreshReportStatus();
            const intervalId = setInterval(() => {
                refreshReportStatus();
            }, 10000);
            return () => clearInterval(intervalId);
        } else {
            navigate(CARD_STATEMENT_HOME_URL);
        }
    }, [])

    const [errorMessage] = statementReport.errorMessages;

    return (
        <>
            <Box>
                {statement.data &&
                    <Ptable
                        tableHeaders={cardDetailsHeaders}
                        tableBodies={cardDetailsFields}
                        data={[getCardData()]}
                        testId="card-statement-list-table"
                    />}
            </Box>
            <Box sx={{mt: 10}}>
                <CardStatementGenerateForm cardId={params.get(CARD_ID_FIELD)}
                                           refreshReports={() => refreshReportStatus()}/>
                {data && <Ptable
                    noDataFoundMessage={errorMessage}
                    tableHeaders={cardStatementHeaders}
                    tableBodies={cardStatementFields}
                    data={dataWithDates}
                    actions={reportTableActions}
                />}
            </Box>
            <TableNavigator
                currentLength={dataWithDates.length}
                LIMIT={LIMIT}
                TOTAL={TOTAL}
                OFFSET={OFFSET}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
            />
        </>
    )
}
