import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Input from '../../common/components/base/input/Input';
import {
    Grid,
    InputAdornment,
    IconButton,
    Box,
    FormHelperText,
} from '@mui/material';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Button from '../../common/components/base/button/Button';
import { resetPassword } from '../thunk';
import { useNavigate, useLocation } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import Title from '../../common/components/base/title/Title';
import globalVariables from "../../common/constants/globalVariables";
import { validateIfFieldValueExists, validatePassword } from '../../common/utils/validation';
import _ from 'lodash';
import useMessageAlert from '../../common/hooks/useMessageAlert';
import Alert from '../../common/components/alert/Alert';

export default function ResetPassword(props) {
    const [stateValues, setStateValues] = useState({
        newPassword: '',
        confirmPassword: '',
        recaptchaResponse: '',
        resetUUID: ''
    })

    const [validationErrors, setValidationErrors] = useState({
        newPassword: '',
        confirmPassword: '',
        recaptchaResponse: ''
    })

    const { showAlert, setShowAlert } = useMessageAlert();

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState({
        newPassword: false,
        confirmPassword: false
    });

    const dispatch = useDispatch();

    const location = useLocation();

    useEffect(() => {
        let params = new URLSearchParams(location.search);
        let resetUUID = params.get("resetPassword");
        setStateValues(prevStateValues => ({ ...prevStateValues, resetUUID }));
        if (!resetUUID)
            navigate("/");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleInput = input => event => {
        let value = event.target.value;
        const inputVsValidationMethod = {
            newPassword: validatePassword,
            confirmPassword: _.partial(validateIfFieldValueExists, 'Confirm password'),
        }
        setValidationErrors(validationErrors => ({
            ...validationErrors,
            [input]: inputVsValidationMethod[input](value)
        }));
        setStateValues(prevStateValues => ({ ...prevStateValues, [input]: value }));
    };

    const getReCaptcha = (recaptchaResponse) => {
        setStateValues(prevStateValues => ({ ...prevStateValues, recaptchaResponse }));
        setValidationErrors(validationErrors => ({ ...validationErrors, recaptchaResponse: "" }));
    };

    const handleOk = () => {
        let errorCount = 0;
        if (stateValues.newPassword.length === 0) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, newPassword: "Password is required." }));
        }
        if (stateValues.confirmPassword.length === 0) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, confirmPassword: "Confirm Password is required." }));
        }
        if (stateValues.newPassword.length !== 0 && stateValues.confirmPassword.length !== 0 && stateValues.newPassword !== stateValues.confirmPassword) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, confirmPassword: "Password and Confirm Password does not match." }));
        }
        if (!stateValues.recaptchaResponse) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, recaptchaResponse: "Please validate recaptcha." }));
        }
        if (errorCount === 0) {
            dispatch(resetPassword(stateValues))
                .then((response) => {
                    if (response.type === "login/reset_password/fulfilled") {
                        setShowAlert({
                            open: true,
                            message: "Reset Password Successfully!",
                            severity: "success"
                        });
                        setTimeout(() => {
                            navigate("/");
                        }, 1000)
                    }
                    else {
                        setShowAlert({
                            open: true,
                            message: response.payload,
                            severity: "error"
                        });
                    }
                })
        }
    }

    const closeAlert = () => {
        setShowAlert(prevShowAlertState => ({
            ...prevShowAlertState,
            open: false,
            message: ""
        }));
    }

    return (
        <Box
            mx="auto"
            alignItems="center"
            justifyContent="center"
            sx={{
                width: 450
            }}
        >
            <Grid
                container
            >
                <Grid item xs={12}>
                    <Title
                        title="Reset Password"
                        subtitle="Fill in the form below to reset your password."
                        sxSubtitle={{ mb: 0 }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        size="small"
                        type={showPassword.newPassword ? "text" : "password"}
                        inputLabel="New Password"
                        name="newPassword"
                        fullWidth
                        autoComplete="newPassword"
                        value={stateValues.newPassword}
                        onChange={handleInput("newPassword")}
                        error={validationErrors.newPassword ? true : false}
                        helperText={validationErrors.newPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowPassword(prevShowPassword => ({ ...prevShowPassword, newPassword: !showPassword.newPassword }))}
                                        edge="end"
                                    >
                                        {showPassword.newPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        size="small"
                        type={showPassword.confirmPassword ? "text" : "password"}
                        inputLabel="Confirm Password"
                        name="confirmPassword"
                        fullWidth
                        autoComplete="confirmPassword"
                        value={stateValues.confirmPassword}
                        onChange={handleInput("confirmPassword")}
                        error={validationErrors.confirmPassword ? true : false}
                        helperText={validationErrors.confirmPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowPassword(prevShowPassword => ({ ...prevShowPassword, confirmPassword: !showPassword.confirmPassword }))}
                                        edge="end"
                                    >
                                        {showPassword.confirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} my={3}>
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <ReCAPTCHA
                            sitekey={globalVariables.RECAPTCHA_KEY}
                            size="normal"
                            onChange={getReCaptcha}
                        />
                    </Grid>
                    <FormHelperText error sx={{ ml: 2 }}>{validationErrors.recaptchaResponse}</FormHelperText>
                </Grid>
                {showAlert.open && (
                <Grid item xs={12}>
                    <Alert
                        severity={showAlert.severity}
                        onClose={closeAlert}
                        message={showAlert.message}
                    />
                </Grid>
                )}
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <Button variant="contained" size="medium" onClick={handleOk}>RESET PASSWORD</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
