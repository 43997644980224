import { useState } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    TableSortLabel
} from "@mui/material";

import AvailableCardItem from "./AvailableCardItem";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../../../../common/components/base/text";
import { cardAllocationActions } from "../../../slice";
import Button from "../../../../../common/components/base/button/Button";
import { fetchCards } from "../../../thunk";
import { prevDisabledStyles } from "../../../common/utils";
import { visuallyHidden } from '@mui/utils';

const headCells = [
    {
        id: "ACCOUNTCARDNUMBER",
        label: "CARD NUMBER",
        sortingKey: "cardNumber"
    },
    {
        id: "VOUCHERCARDDATATRACKINGNUMBER",
        label: "TRACKING NUMBER",
        sortingKey: "cardTracking"
    },
    {
        id: "VOUCHERIDX",
        label: "SEQUENCE NUMBER",
        sortingKey: "cardSequence"
    },
    {
        id: "ACCOUNTCARDEXPIRYDATE",
        label: "EXPIRY DATE",
        sortingKey: "cardExpiry"
    },
];

function AvailableCardsHead(props) {
    const { onSelectAllClick, numSelected, rowCount, createSortHandler, sortingOrderBy, sortingOrder } = props;

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        name="selectAllCard"
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all cards",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id}>
                        <TableSortLabel
                            active={sortingOrderBy === headCell.sortingKey}
                            direction={sortingOrderBy === headCell.sortingKey ? sortingOrder : 'asc'}
                            onClick={createSortHandler(headCell.sortingKey)}
                        >
                            <Text light>
                                {headCell.label}
                            </Text>
                            {sortingOrderBy === headCell.sortingKey &&
                                <Box component="span" sx={visuallyHidden}>
                                    {sortingOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function AvailableCardsList() {
    const [sortingOrder, setSortingOrder] = useState('asc');
    const [sortingOrderBy, setSortingOrderBy] = useState('cardNumber');

    const dispatch = useDispatch();

    const { cards } = useSelector((state) => state.cardAllocation);
    const { OFFSET, LIMIT, TOTAL } = cards.metadata || {};

    const selectedCards = useSelector(
        (state) => state.cardAllocation.selectedCards
    );

    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            dispatch(cardAllocationActions.multipleCardsSelected(cards.data));
        } else {
            dispatch(cardAllocationActions.allCardsUnSelected(null));
        }
    };

    const handleNext = () => {
        let nextOffset = OFFSET + LIMIT;
        dispatch(fetchCards({ ...cards.filter, limit: LIMIT, offset: nextOffset, orderByField: sortingOrderBy, orderAsc: sortingOrder === 'asc' }))
    }

    const handlePrevious = () => {
        let prevOffset = OFFSET - LIMIT;
        dispatch(fetchCards({ ...cards.filter, limit: LIMIT, offset: prevOffset, orderByField: sortingOrderBy, orderAsc: sortingOrder === 'asc' }))
    }

    const nextIsDisabled = () => {
        return (LIMIT + OFFSET) >= TOTAL;
    }

    const prevIsDisabled = () => {
        return (OFFSET === 0);
    }

    const createSortHandler = (property) => (_event) => {
        const isAsc = sortingOrderBy === property && sortingOrder === 'asc';
        setSortingOrder(isAsc ? 'desc' : 'asc');
        setSortingOrderBy(property);
        dispatch(fetchCards({ ...cards.filter, limit: LIMIT, offset: 0, orderByField: property, orderAsc: !isAsc }))
    };

    return (
        <Box sx={{ width: "100%", mt: 3 }}>
            <Box sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                    <Table aria-labelledby="AvailableCards">
                        <AvailableCardsHead
                            numSelected={selectedCards.length}
                            onSelectAllClick={handleSelectAllChange}
                            rowCount={cards.data.length}
                            createSortHandler={createSortHandler}
                            sortingOrder={sortingOrder}
                            sortingOrderBy={sortingOrderBy}
                        />
                        <TableBody>
                            {cards.data.map((card, index) => {
                                return (
                                    <AvailableCardItem
                                        card={card}
                                        key={index + "-available-card"}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
                <Box >
                    <Text>
                        Records {OFFSET + 1} - {OFFSET + cards.data.length} of {TOTAL}
                    </Text>
                </Box>
                <Box sx={{ display: "flex" }}>
                    <Box>
                        <Button
                            onClick={handlePrevious}
                            size="small"
                            style={prevIsDisabled() ? prevDisabledStyles : {}}
                            disabled={prevIsDisabled()}
                            variant="outlined"
                        >
                            Previous
                        </Button>
                    </Box>
                    <Box sx={{ ml: 3 }}>
                        <Button
                            onClick={handleNext}
                            size="small"
                            disabled={nextIsDisabled()}
                            style={{ opacity: nextIsDisabled() ? 0.5 : 1 }}
                            variant="contained"
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            </Box>
            <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)", marginTop: 20 }} />
        </Box>
    );
}
