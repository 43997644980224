import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';

import Input from '../../common/components/base/input/Input';
import Button from '../../common/components/base/button/Button';
import Title from '../../common/components/base/title';
import { fetchSetupMfaData, verifyTotpToken } from '../thunk';
import { verifyLogout } from "../slice";

function isValidMessage(obj) {
  return typeof obj === 'string' && !!obj;
}

const ShowIfNoErrorMessage = ({ children, visible, errorMessage }) => {
  if (visible && isValidMessage(errorMessage)) {
    return null;
  }
  return children;
};
const MfaSetupForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const errorMessages = useSelector(state => state.login.errorMessages);

  const [stateValues, setStateValues] = useState({
    otpCode: '',
    userTokenSecret: '',
    userName: '',
    setupMfaUuid: '',
  });


  const invalidSetupMfa = !stateValues.userTokenSecret;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const setupMfa = params.get('setupMfa');
    if (setupMfa) {
      setStateValues(prevStateValues => ({
        ...prevStateValues,
        setupMfaUuid: setupMfa,
      }));
      dispatch(fetchSetupMfaData({ setupMfaUuid: setupMfa })).then(response => {
        if (response.type === 'login/setup_mfa_data/fulfilled') {
          setStateValues(prevStateValues => ({
            ...prevStateValues,
            userName: response.payload.userName,
            userTokenSecret: response.payload.userTokenSecret,
          }));
        }
      });
    } else {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const issuer = 'Paycard-Portal';
  const authCode = `otpauth://totp/${issuer}:${stateValues.userName}?secret=${stateValues.userTokenSecret}&issuer=${issuer}`;

  const [validationErrors, setValidationErrors] = useState({
    otpCode: '',
  });

  const [openAlert, setOpenAlert] = useState(false);

  const handleInput = input => event => {
    const value = event.target.value;
    if (input === 'otpCode' && value.length === 0) {
      setValidationErrors(validationErrors => ({
        ...validationErrors,
        otpCode: 'OTP Code is required.',
      }));
    }
    setStateValues(prevStateValues => ({ ...prevStateValues, [input]: value }));
  };

  const handleSubmit = () => {
    let errorCount = 0;
    if (stateValues.otpCode.length === 0) {
      errorCount++;
      setValidationErrors(validationErrors => ({
        ...validationErrors,
        otpCode: 'OTP Code is required.',
      }));
    }
    if (errorCount === 0) {
      dispatch(verifyTotpToken(stateValues)).then(response => {
        if (response.type === 'login/verify_totp_token/fulfilled') {
          setOpenAlert(true);
        }
      });
    }
  };

  const closeAlert = () => {
    setOpenAlert(false);
    dispatch(verifyLogout());
  };

  return (
    <Box
      mx="auto"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      sx={{ width: { xs: '50%', sm: '75%', md: '100%' } }}
    >
      <Grid container>
        <ShowIfNoErrorMessage errorMessage={errorMessages} visible={invalidSetupMfa}>
          <Grid item xs={12}>
            <Title
              subtitle="Scan this QR Code with your authenticator application
                            to generate a code."
              sxSubtitle={{ mb: 0 }}
            />
          </Grid>
          <Grid item xs={12}>
            {authCode && <QRCode data-testid="qrcode" value={authCode} />}
          </Grid>
          <Grid item xs={12} mt={1}>
            {!!stateValues.userTokenSecret && (
              <i data-testid="secretKeyValue">{stateValues.userTokenSecret}</i>
            )}
          </Grid>
          <Grid item xs={12} mt={3}>
            <Typography variant="body2" mt={0.5} ml={0.5}>
              If you are unable to scan the QR code, type the secret key (16-digit alpha-numeric code) within your authentication app.
            </Typography>
            <Typography variant="body2" mt={0.5} ml={0.5}>
              Note: The Authenticator app will provide a 6-digit numerical OTP code. Type the OTP code in the OTP field and click Verify.
            </Typography>
          </Grid>
        </ShowIfNoErrorMessage>
        <Grid item xs={12} mt={1}>
          {isValidMessage(errorMessages) && (
            <Grid item xs={12} display="flex">
              <Alert
                sx={{ width: '100%' }}
                key="danger"
                variant="outlined"
                severity="error"
                data-testid="alert-message"
                mt={0.5}
                ml={0.5}
              >
                {errorMessages}
              </Alert>
            </Grid>
          )}
        </Grid>
        <ShowIfNoErrorMessage errorMessage={errorMessages} visible={invalidSetupMfa}>
          <Grid container xs={12} direction="column" alignItems="center">
            <Grid item>
              <Input
                size="small"
                inputLabel="OTP Code"
                name="otpCode"
                fullWidth
                autoComplete="otp-code"
                autoFocus
                value={stateValues.otpCode}
                onChange={handleInput('otpCode')}
                error={!!validationErrors.otpCode}
                helperText={validationErrors.otpCode}
                data-testid="otp-code-field"
              />
                <Grid item my={3}>
                <Box>
                  <Button
                      variant="contained"
                      size="medium"
                      onClick={handleSubmit}
                      data-testid="btn-otp-submit"
                  >
                    Verify
                  </Button>
              </Box>
                </Grid>
            </Grid>
          </Grid>
        </ShowIfNoErrorMessage>
        <Dialog
          open={openAlert}
          onClose={closeAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Alert severity="success" sx={{ mt: 3 }}>
                Set up for MFA is successful. Please log in again with MFA.
              </Alert>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert} autoFocus data-testid="btn-otp-ok">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
};

export default MfaSetupForm;
