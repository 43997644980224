import React from "react";
import { Box } from "@mui/material";
import Button from "../button/Button";
import PropTypes from "prop-types";

import Text from "../../../../common/components/base/text";
import { prevDisabledStyles } from "../../../../cards/allocateCard/common/utils";

export default function TableNavigator({ OFFSET, TOTAL, LIMIT, currentLength, handlePrevious, handleNext }) {

    const nextIsDisabled = () => {
        return (LIMIT + OFFSET) >= TOTAL;
    }

    const prevIsDisabled = () => {
        return !OFFSET;
    }

    return (
        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
            <Box >
                <Text>
                    Records {OFFSET + 1} - {OFFSET + currentLength} of {TOTAL}
                </Text>
            </Box>
            <Box sx={{ display: "flex" }}>
                <Box>
                    <Button
                        onClick={handlePrevious}
                        size="small"
                        style={prevIsDisabled() ? prevDisabledStyles : {}}
                        disabled={prevIsDisabled()}
                        variant="outlined"
                    >
                        Previous
                    </Button>
                </Box>
                <Box sx={{ ml: 3 }}>
                    <Button
                        onClick={handleNext}
                        size="small"
                        disabled={nextIsDisabled()}
                        style={{ opacity: nextIsDisabled() ? 0.5 : 1 }}
                        variant="contained"
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

TableNavigator.propTypes = {
    OFFSET: PropTypes.number.isRequired,
    TOTAL: PropTypes.number.isRequired,
    LIMIT: PropTypes.number.isRequired,
    currentLength: PropTypes.number.isRequired,
    handlePrevious: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
};
