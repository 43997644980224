import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material/";
import { DatePicker } from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { DateTime } from "luxon";
import PropTypes from "prop-types";

import { generateCardStatementReport, } from "../../thunk";
import { CARD_ID_FIELD } from "../../common/utils";

export default function CardStatementGenerateForm({ cardId, refreshReports }) {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(DateTime.now());
    const [endDate, setEndDate] = useState(DateTime.now());
    const [reportType, setReportType] = useState("XLS");

    const generateStatement = () => {
        if (!startDate || !endDate || !cardId) {
            return;
        }
        const payload = {
            [CARD_ID_FIELD]: cardId,
            startDate: startDate,
            endDate: endDate,
            type: reportType,
        }
        dispatch(generateCardStatementReport(payload));
        refreshReports();
    }

    return (
        <Box sx={{ml: 2, mb: -2}}>
            <Typography
                variant="h6"
                sx={{color: "#3c4b6c", fontSize: 18, fontWeight: 400, mb: 2}}
            >
                STATEMENT HISTORY PERIOD
            </Typography>
            <Box sx={{display: 'flex', alignItems: 'center', gap: 2}} data-testid="card-statement-generate-form">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        inputFormat="yyyy-MM-dd"
                        mask="____-__-__"
                        onChange={(newValue) => {
                            setStartDate(newValue);
                            if (endDate && newValue > endDate) {
                                setEndDate(null);
                            }
                        }}
                        renderInput={(params) => <TextField name="startDate" size="small" {...params}
                                                            error={!startDate}/>}
                    />
                    <Typography>to</Typography>
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        inputFormat="yyyy-MM-dd"
                        mask="____-__-__"
                        onChange={(newValue) => setEndDate(newValue)}
                        minDate={startDate}
                        renderInput={(params) => <TextField name="endDate" size="small" {...params} error={!endDate}/>}
                    />
                </LocalizationProvider>
                <FormControl sx={{minWidth: 120}} size="small">
                    <InputLabel id="date-type-select-label">Type</InputLabel>
                    <Select
                        labelId="date-type-select-label"
                        name="reportType"
                        value={reportType}
                        label="Type"
                        onChange={(e) => setReportType(e.target.value)}
                    >
                        <MenuItem value="XLS">XLS</MenuItem>
                        <MenuItem value="PDF">PDF</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={generateStatement}
                    size="small"
                    data-testid="submit-satatement-report"
                >
                    Generate Statement
                </Button>
            </Box>
        </Box>
    );
}

CardStatementGenerateForm.propTypes = {
    cardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    refreshReports: PropTypes.func.isRequired
};
