import { useState } from 'react';

const useMessageAlert = () => {
  const [showAlert, setShowAlert] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  return {
    showAlert,
    setShowAlert,
  };
};

export default useMessageAlert;
