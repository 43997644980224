import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Alert as MuiAlert, Collapse, IconButton } from '@mui/material';

const Alert = ({ severity, message, onClose }) => {
  return (
    <Collapse in>
      <MuiAlert
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ my: 3 }}
      >
        {message}
      </MuiAlert>
    </Collapse>
  );
};

export default Alert;
