import {
    fetchAccountCards,
    fetchCardStatement,
    fetchCardStatementDocumentPDF,
    fetchCardStatementDocumentXLS,
    fetchCardStatementReports,
    generateCardStatementReport
} from "./thunk";
import ReduxStatus from "../../common/constants/ReduxStatus";

const cardStatementActions = {
    cardFilterChanged: (state, action) => {
        state.cards.filter = { ...state.cards.filter, ...action.payload }
    },
    cardStatementViewChanged: (state, action) => {
        state.view = action.payload;
    },
    cardStatementPeriodChanged: (state, action) => {
        state.statement.period = action.payload;
    },
    loadingStatementsErrorMessageShown: (state, action) => {
        state.statement.errorMessageShown = action.payload;
    }
}

export default cardStatementActions;

export const extraReducers = {
    [fetchAccountCards.pending]: (state, action) => {
        state.cards.status = ReduxStatus.loading;
        state.cards.errorMessageShown = false;
    },
    [fetchAccountCards.fulfilled]: (state, action) => {
        state.cards.data = action.payload["ALLOCATEDCARDS"];
        state.cards.metadata = action.payload["METADATA"];
        state.cards.status = ReduxStatus.loaded;
    },
    [fetchAccountCards.rejected]: (state, action) => {
        state.errorMessages = ["Error fetching cards"];
        state.cards.status = ReduxStatus.loadFailed;
    },

    [fetchCardStatement.pending]: (state) => {
        state.statement.status = ReduxStatus.loading;
        state.statement.errorMessageShown = false;
    },
    [fetchCardStatement.fulfilled]: (state, action) => {
        state.statement.data = action.payload;
        state.statement.status = ReduxStatus.loaded;
        if (action.payload.LISTSTATEMENT.length === 0) {
            state.statement.errorMessages = ['There are no transactions to show for this statement.'];
        } else {
            state.statement.errorMessages = [];
        }
    },
    [fetchCardStatement.rejected]: (state, action) => {
        state.statement.status = ReduxStatus.loadFailed;
        state.statement.errorMessages = [action.payload];
    },

    [fetchCardStatementDocumentPDF.pending]: (state) => {
        state.pdf.status = ReduxStatus.loading;
    },
    [fetchCardStatementDocumentPDF.fulfilled]: (state, action) => {
        state.pdf.data = action.payload;
        state.pdf.status = ReduxStatus.loaded;
    },

    [fetchCardStatementDocumentPDF.rejected]: (state) => {
        state.pdf.status = ReduxStatus.loadFailed;
        state.pdf.errorMessages = ["Error loading PDF statement"]
    },

    [fetchCardStatementDocumentXLS.pending]: (state) => {
        state.docDownload.status = ReduxStatus.loading;
    },
    [fetchCardStatementDocumentXLS.fulfilled]: (state, action) => {
        state.docDownload.data = action.payload;
        state.docDownload.status = ReduxStatus.loaded;
    },
    [fetchCardStatementDocumentXLS.rejected]: (state) => {
        state.docDownload.status = ReduxStatus.loadFailed;
        state.docDownload.errorMessages = ["Error Downloading Statement report"]
    },

    [generateCardStatementReport.pending]: (state) => {
        state.generateStatementReport.status = ReduxStatus.loading;
    },
    [generateCardStatementReport.fulfilled]: (state) => {
        state.generateStatementReport.status = ReduxStatus.loaded;
    },
    [generateCardStatementReport.rejected]: (state, action) => {
        state.generateStatementReport.status = ReduxStatus.loadFailed;
        state.generateStatementReport.errorMessages = action.payload?.response?.data?.split(",")[0];
    },

    [fetchCardStatementReports.pending]: (state) => {
        state.statementReport.status = ReduxStatus.loading;
    },
    [fetchCardStatementReports.fulfilled]: (state, action) => {
        state.statementReport.data = action.payload["statementReports"];
        state.statementReport.metadata = action.payload["METADATA"];
        state.statementReport.status = ReduxStatus.loaded;
    },
    [fetchCardStatementReports.rejected]: (state) => {
        state.statementReport.status = ReduxStatus.loadFailed;
        state.statementReport.errorMessages = ["Error Fetching Statement reports"]
    },
}
