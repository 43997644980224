import React from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import _ from 'lodash';
import DownloadIcon from "@mui/icons-material/Download";
import PropTypes from "prop-types";


const getProperty = (obj, prop) => {
    var parts = prop.split(".");

    if (Array.isArray(parts)) {
        var last = parts.length > 1 ? parts.pop() : parts;
        var l = parts.length,
            i = 1,
            current = parts[0];

        while ((obj = obj[current]) && i < l) {
            current = parts[i];
            i++;
        }

        if (typeof obj === "object") {
            return obj[last];
        }
        return obj;
    } else {
        throw Error("parts is not valid array");
    }
};



const useStyles = makeStyles((theme) =>
    createStyles({
        iconplacing: {
            marginTop: 10
        },
        table: {
            fontSize: '30px !important',
        },
        Header: {
            fontWeight: '400 !important',
            color: '#848D9E !important',
            textTransform: 'uppercase',
            fontSize: '14px !important',


        },
        tBody: {
            fontWeight: '400 !important',

            fontSize: '16px !important',
        },
        rowLine: {
            borderBottom: '2px solid rgba(224, 224, 224, 1) !important',

        },
        disabledLink: {
            pointerEvents: "none",
            color: "grey",
            textDecoration: "none"
        },
        anchorBlue: {
            color: "blue",
            textDecoration: "none"
        }

    }),
)
const ButtonLink = prop => {
    return (
      <Button
        to={prop.link}
        variant="contained"
        type="button"
        size="small"
        className={"button-classes"}
        startIcon={prop.icon}
      />
    );
  };
export default function Ptable({ data, tableHeaders, tableBodies, noDataFoundMessage, testId, actions = [] }) {
    const classes = useStyles()
    return (
        <Paper elevation={0} sx={{ mt: 5 }} >
            <TableContainer elevation={0}>
                <Table aria-label="simple table" className={classes.table} elevation={0} data-testid={testId}>
                    <TableHead>
                        <TableRow>
                            {tableHeaders.map((header, index) => (
                                <TableCell className={classes.Header} key={index}>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {!_.isEmpty(noDataFoundMessage) && <TableRow className={classes.rowLine}>
                        <TableCell key={"table_cell_no_data"} className={classes.tBody} colspan="4">{noDataFoundMessage}</TableCell>
                    </TableRow>}
                        {data.map(data => (
                            <TableRow key={data.id} sx={{ pt: 6 }} className={classes.rowLine}>
                                {tableBodies.map(body =>
                                    typeof body === "string" ? (
                                        <TableCell className={classes.tBody} key={body} sx={{ pt: 6 }}>{getProperty(data, body)}</TableCell>
                                    ) : (
                                        <TableCell key={body} sx={{ pt: 6 }}>
                                            <ButtonLink link={body.base} icon={body.icon} />
                                        </TableCell>
                                    )
                                )}
                                {actions.map((action) => (
                                    <TableCell key={`${action.type}-${data.id}`} sx={{ pt: 6 }}>
                                        {action.type === "download" &&
                                            <a href={data.downloadLink} target="_blank" className={data.status === "COMPLETED" ? classes.anchorBlue : classes.disabledLink}>
                                                <DownloadIcon />
                                            </a>}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

Ptable.propTypes = {
    data: PropTypes.array.isRequired,
    tableHeaders: PropTypes.array.isRequired,
    tableBodies: PropTypes.array.isRequired,
    noDataFoundMessage: PropTypes.string,
    testId: PropTypes.string,
    actions: PropTypes.array,
};
