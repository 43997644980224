import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";

import ReduxStatus from "../../../../common/constants/ReduxStatus";

import Title from "../../../../common/components/base/title";
import Loader from "../../../../common/components/base/loader/Loader";

import CardStatementReportList from "./CardStatementReportList";
import useCustom from "../../../../common/components/hooks";

export default function CardStatement() {
	const { statement, generateStatementReport } = useSelector(state => state.cardStatement);
	const { showAlert } = useCustom();

	const getSubtitle = () => `${new Date().toDateString()}  at
                         ${new Date().getHours().toString().padStart(2, '0')}:${new Date().getMinutes().toString().padStart(2, '0')}`;

	const isLoading = statement.status === ReduxStatus.loading || generateStatementReport.status === ReduxStatus.loading;

	useEffect(() => {
		if (generateStatementReport.status === ReduxStatus.loadFailed) {
			showAlert(generateStatementReport.errorMessages, "info");
		}
	}, [generateStatementReport.status]);

	return (
		<>
			<Container>
				<Title
					title="Transaction statement"
					subtitle={getSubtitle()}
					subtitleArray={[]}
				/>
				<CardStatementReportList/>
			</Container>
			{isLoading && <Loader open={true} />}
		</>
	)
}
