import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Collapse, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Input from "../../common/components/base/input/Input";
import Button from "../../common/components/base/button/Button";
import ReduxStatus from "../../common/constants/ReduxStatus";
import Loader from '../../common/components/base/loader/Loader';
import { verifyResetMfa } from "./thunk";

const ResetMfaForm = () => {

    const [formData, setFormData] = useState({
        otpCode: ''
    })
    const [validateMessage, setValidateMessage] = useState('')
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const status = useSelector(state => state.resetMfa.status);

    const dispatch = useDispatch();
    const isLoading = status === ReduxStatus.loading;

    const handleInput = event => {
        const value = event.target.value;
        if (value.length === 0) {
                setValidateMessage("MFA Code is required.")
        }
        setFormData({otpCode: value});
    }

    const handleSubmit = event => {
        if (formData.otpCode.trim() === '') {
            setValidateMessage("MFA Code is required.");
            return;
        }
        dispatch(verifyResetMfa(formData));
    }

    useEffect(() => {
        if (status === ReduxStatus.loadFailed) {
            setIsAlertOpen(true);
        }
    }, [status, dispatch]);

    return (
        <>
            <Loader open={isLoading}/>
            <Grid data-testid={'reset-mfa-form'} container spacing={1} p={1} justifyContent={'center'}>
                {status === ReduxStatus.loaded && <div>
                    <Typography
                        variant='subtitle1'
                        sx={{color: 'primary.light', fontSize: 20, fontWeight: 400}}
                        align={'left'}
                    >
                        Instructions to setup your new MFA have been sent to your email address
                    </Typography>
                </div>}
                {status !== ReduxStatus.loaded &&
                    <div>
                        <Grid item xs={12} display="flex" justifyContent={'center'}>
                            <Input
                                size="small"
                                inputLabel="MFA Code"
                                name="challenge"
                                fullWidth
                                autoComplete="otp-code"
                                autoFocus
                                value={formData.otpCode}
                                onChange={handleInput}
                                error={!!validateMessage}
                                helperText={validateMessage}
                                data-testid="otp-code-field"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Collapse in={isAlertOpen}>
                                <Alert
                                    severity="error"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setIsAlertOpen(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit"/>
                                        </IconButton>
                                    }
                                    sx={{mt: 1}}
                                >
                                    Invalid MFA code, please try again!
                                </Alert>
                            </Collapse>
                        </Grid>

                        <Grid item mt={3} xs={12} display="flex" justifyContent={'center'}>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleSubmit}
                                data-testid="btn-otp-submit"
                            >
                                Reset MFA
                            </Button>
                        </Grid>
                    </div>}
            </Grid>
        </>
    )
}

export default ResetMfaForm;
