import { createAsyncThunk } from "@reduxjs/toolkit";
import cardStatementService from "./service";
import { Buffer } from "buffer";

export const fetchAccountCards = createAsyncThunk(
    "cardStatement/fetchAccountCards",
    async (filters, { rejectedWithValue }) => {
        try {
            let response = await cardStatementService.fetchAccountCards(filters);
            return response.data;
        } catch (error) {
            return rejectedWithValue(error);
        }
    }
);

export const fetchCardStatement = createAsyncThunk(
    "cardStatement/fetchCardStatement",
    async (filters, { rejectWithValue }) => {
        try {
            let response = await cardStatementService.fetchCardStatement(filters);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchCardStatementDocumentPDF = createAsyncThunk(
    "cardStatement/fetchStatementsDocumentPDF",
    async (filters, { rejectWithValue }) => {
        try {
            let response = await cardStatementService.fetchCardStatementDocumentPDF(filters);
            const pdfInBase64 = new Buffer.from(response.data).toString('base64');
            return 'data:application/pdf;base64,' + pdfInBase64;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchCardStatementDocumentXLS = createAsyncThunk(
    "cardStatement/fetchStatementsDocumentXLS",
    async (filters, { rejectWithValue }) => {
        try {
            let response = await cardStatementService.fetchCardStatementDocumentXLS(filters);
            const docInBase64 = new Buffer.from(response.data).toString('base64');
            return 'data:application/vnd.ms-excel;base64,' + docInBase64;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const generateCardStatementReport = createAsyncThunk(
    "cardStatement/generateCardStatementReport",
    async (data, { rejectWithValue }) => {
        try {
            await cardStatementService.generateCardStatementReport(data);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchCardStatementReports = createAsyncThunk(
    "cardStatement/fetchCardStatementReports",
    async (filters, { rejectWithValue }) => {
        try {
            const response = await cardStatementService.fetchCardStatementReports(filters);
            return response.data;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);
